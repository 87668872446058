const colors = {
  primary: {
    50: "#FFD9DA",
    100: "#FFB9BD",
    200: "#FB828D",
    300: "#F55266",
    400: "#F12C51",
    500: "#E00C34",
    600: "#CD082D",
    700: "#B50828",
    800: "#9A0A24",
    900: "#7D0C21",
  },
  secondary: {
    50: "#D1DDF8",
    100: "#B2C7F2",
    200: "#7A9FE8",
    300: "#4B80DD",
    400: "#2969CE",
    500: "#0E56C2",
    600: "#054CB2",
    700: "#03459C",
    800: "#013B82",
    900: "#003066",
  },
  success: {
    50: "#DBFDEA",
    100: "#C5FBDC",
    200: "#99F5BF",
    300: "#73EDA4",
    400: "#54E18A",
    500: "#3DD072",
    600: "#2CBB5B",
    700: "#27A24C",
    800: "#008542",
    900: "#1E662F",
  },
  warning: {
    50: "#FFF9D5",
    100: "#FFF5B7",
    200: "#FFE67A",
    300: "#FFD344",
    400: "#FFBE19",
    500: "#EAA500",
    600: "#CF9000",
    700: "#AF7600",
    800: "#8B5B00",
    900: "#664100",
  },
  gray: {
    50: "#F7F7F8",
    100: "#EFEFEF",
    150: "#DDDDDD",
    200: "#D9D9D9",
    300: "#AFAFAF",
    400: "#8B8B8B",
    500: "#555555",
    600: "#373737",
    700: "#252525",
    800: "#1C1C1E",
    900: "#121212",
  },
  blackAlpha: {
    300: "rgba(0, 0, 0, 0.29)",
    600: "rgba(0, 0, 0, 0.6)",
    700: "rgba(0, 0, 0, 0.7)",
  },
  common: {
    black: "rgba(17, 17, 17, 1)",
    text: "rgba(17, 17, 17, 0.7)",
    gray: "rgba(17, 17, 17, 0.12)",
  },
};

export default colors;

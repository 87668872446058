import { FC, useEffect } from "react";

import { Box } from "@chakra-ui/react";
import parse from "html-react-parser";

import { IBlock } from "../../features/cms/types";

const SCRIPT_ID = "OMNISEND_SCRIPT";

const SubscribeInput: FC<{ omnisendId: string | null; omnisendBlock?: IBlock }> = ({ omnisendId, omnisendBlock }) => {
  const parsedContent = omnisendBlock?.html ? parse(omnisendBlock?.html) : null;

  useEffect(() => {
    const s = document.createElement("script");
    const currentScript = document.getElementById(SCRIPT_ID);

    if (omnisendBlock?.html && omnisendId && !currentScript) {
      s.id = SCRIPT_ID;
      s.type = "text/javascript";
      s.appendChild(
        document.createTextNode(
          // eslint-disable-next-line max-len
          `window.omnisend = window.omnisend || [];omnisend.push(["accountID", "${omnisendId}"]);!function(){var e=document.createElement("script");e.type="text/javascript",e.async=!0,e.src="https://omnisnippet1.com/inshop/launcher-v2.js";var t=document.getElementsByTagName("script")[0];t.parentNode.insertBefore(e,t)}();`
        )
      );
      delete window.OMNISEND_LAUNCHER_LOADED;
      document.body.appendChild(s);
    }

    return () => {
      const e = document.getElementById(SCRIPT_ID);

      if (e) {
        e.parentNode?.removeChild(e);
      }
    };
  }, [omnisendBlock?.html, omnisendId]);

  return <Box width="100%">{omnisendBlock?.html && omnisendId && parsedContent}</Box>;
};

export default SubscribeInput;

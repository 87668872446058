/* eslint-disable @typescript-eslint/naming-convention */
import { AxiosError, AxiosRequestConfig } from "axios";
import { getSession } from "next-auth/react";

import { getUserLocalization } from "@modules/Localization";
import { getXLocalizationHeader } from "@modules/Localization/utils";
import { IServerError } from "@utils/types";

import { logger } from "../logger/client";
import { X_AUTH_KEY } from "./constants";

// eslint-disable-next-line @typescript-eslint/ban-types
export const getAxiosErrorResponse = (error: AxiosError): IServerError => error?.response?.data as IServerError;

interface IHeadersMiddlewareOptions {
  sessionRequired?: boolean;
}

export const headersMiddleware =
  (options?: IHeadersMiddlewareOptions) =>
  async (request: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    logger.debug(`API request (axiosInterceptor): ${request.url}`);

    const localization = getUserLocalization();
    const localizationString = getXLocalizationHeader(localization);

    const session = options?.sessionRequired ? await getSession() : null;

    request.headers = {
      ...request.headers,
      ...(localizationString ? { "X-Localization": localizationString } : {}),
      ...(X_AUTH_KEY ? { "X-Auth-Key": X_AUTH_KEY } : {}),
      ...(session?.accessToken
        ? {
            Authorization: `Bearer ${session.accessToken}`,
          }
        : {}),
    };

    logger.debug(`API request (axiosInterceptor: headers): ${JSON.stringify(request.headers)}`);

    return request;
  };

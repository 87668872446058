import { createContext, FC, ReactNode, useCallback, useContext, useMemo } from "react";

import { TAppStaticPath } from "@utils/app-routes";

type TStaticPathContext = TAppStaticPath & {
  getProductPath: (slug: string) => string;
  getOrderStatusPath: (id: string) => string;
  getOrderPath: (token: string) => string;
  getUserAddressPath: (id: number) => string;
};
const StaticPathContext = createContext<TStaticPathContext | null>(null);

interface IStaticPathContextProps {
  children: ReactNode;
  initialState: TAppStaticPath;
}

export const AppStaticPathContext: FC<IStaticPathContextProps> = ({ children, initialState }) => {
  /**
   * `product` by `slug` path.
   */
  const getProductPath = useCallback(
    (slug: string) => `${initialState.productsPath}/${slug}`,
    [initialState.productsPath]
  );

  /**
   * `order-status` page with query `id` path.
   */
  const getOrderStatusPath = useCallback(
    (id: string) => `${initialState.orderStatusPath}?id=${id}`,
    [initialState.orderStatusPath]
  );

  /**
   * Users account `order` page by `orderToken` path.
   */
  const getOrderPath = useCallback((token: string) => `${initialState.ordersPath}/${token}`, [initialState.ordersPath]);

  /**
   * Users account `address` page by `addressId` path.
   */
  const getUserAddressPath = useCallback(
    (id: number) => `${initialState.addressesPath}/${id}`,
    [initialState.addressesPath]
  );

  const state = useMemo(
    () => ({
      ...initialState,
      getProductPath,
      getOrderStatusPath,
      getOrderPath,
      getUserAddressPath,
    }),
    [initialState, getOrderPath, getOrderStatusPath, getProductPath, getUserAddressPath]
  );

  return <StaticPathContext.Provider value={state}>{children}</StaticPathContext.Provider>;
};

export const useAppStaticPath = (): TStaticPathContext => {
  const appAppStaticPathContext = useContext(StaticPathContext);

  if (!appAppStaticPathContext) {
    throw new Error("useAppStaticPath has to be used within <AppStaticPathContext />");
  }

  return appAppStaticPathContext;
};

import { TAddress } from "@modules/AddressForm/types";

export interface IUserManual {
  filename: string;
  path: string;
}

export interface IPrice {
  current: number;
  currency: string;
}

export interface IImage {
  path: string;
}

export enum ShippingState {
  CART = "cart",
  READY = "ready",
  CANCELLED = "cancelled",
  SHIPPED = "shipped",
}

export interface IProductVideo {
  path: string;
}

export interface IShippingMethodImage {
  id: number;
  type: string;
  path: string;
}

export interface IShippingMethod {
  code: string;
  name: string;
  description: string;
  price: IPrice;
  image?: IShippingMethodImage;
  type: string;
  city?: string;
  dpdPickupId?: string;
  dpdPickUp?: boolean;
}

export interface IAvailableShippingMethods {
  methods: IShippingMethod[];
  hasRestrictedProducts: boolean;
}

export interface IShipment {
  state: ShippingState;
  method: IShippingMethod;
}

export enum MediaType {
  IMAGE = "image",
  VIDEO = "video",
}

export type TProductList = { product: IProduct }[];
export type TTaxon = {
  code: string;
  name: string;
};
export interface IOrderDetails {
  email: string;
  notes?: string;
}

export type TPaymentData = {
  method?: string;
  orderId?: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  payment_method_nonce?: string;
  customer?: {
    firstName: string | null;
    lastName: string | null;
    email: string | null;
  };
};
export interface ICartItemOptions {
  quantity: number;
  autoRedeem?: boolean;
  redeemQuantity?: number;
}
export interface IAddItemToCartBody extends ICartItemOptions {
  productCode: string;
}
export type TAttribute = {
  code: string;
  name: string;
  type: string;
  value: string;
};
export type TAttributes = TAttribute[];

export type TPaymentDetails = {
  paymentOrderId: string;
  status: string;
  code: string;
};

export enum OrderPaymentState {
  PAID = "paid",
  PENDING = "awaiting_payment",
}

export type TEnablePaymentMethods = {
  googlePay: boolean;
  applePay: boolean;
};

export interface INewCustomerDetails {
  firstName: string;
  lastName: string;
  email: string;
  terms: boolean;
}

export type TDeliveryAndBillingAddress = {
  shippingAddress: TAddress;
  billingAddress: TAddress;
  email: string;
};

export interface IProductMedia {
  path: string;
  type: MediaType;
}

export interface ICarBrandImage extends IImage {
  type: string;
}

export interface ICarBrand {
  code: string;
  title: string;
  voltasMappingName: string;
  images: ICarBrandImage[];
}

export interface IProduct {
  code: string;
  name: string;
  slug: string;
  channelCode: string;
  description: string;
  shortDescription?: string;
  metaTitle?: string;
  metaKeywords?: string;
  metaDescription?: string;
  taxons: {
    main: string;
    others: [];
  };
  variants: IProduct[];
  parent?: IProduct;
  price: IPrice;
  originalPrice?: IPrice;
  discountPercentage: number;
  digital: boolean;
  plan: boolean;
  variantBlockTitle?: string;
  variantSubtitle?: string;
  attributes: TAttributes | [];
  availableForCompare: boolean;
  images: IImage[];
  videos: IProductVideo[];
  media: IProductMedia[];
  carBrandGroup: {
    id: number;
    code: string;
    carBrands: ICarBrand[];
  };
  productLabels: {
    code: string;
    name: string;
  }[];
  additionalCondition: string;
  bundle: boolean;
  productBundleItems: {
    items: TProductList;
  };
  userManual?: IUserManual;
  largeProductCard: boolean;
  itemsLeft?: number | undefined;
  hidden: boolean;
  lowStockWarning: boolean;
  showPrice?: boolean;
  noBundleRedeemRequired?: boolean;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  status_code?: number;
}

export interface IProductItem {
  id: number;
  quantity: number;
  total: number;
  product: IProduct;
  redeemable: boolean;
  autoRedeem: boolean;
  redeemQuantity?: number;

  carBrandGroup: {
    code: string;
  };
  taxons: {
    main: string;
  };
}

export enum ServerCheckoutState {
  CART = "cart",
  ADDRESSED = "addressed",
  SHIPPING_SELECTED = "shipping_selected",
  PAYMENT_SELECTED = "payment_selected",
  COMPLETED = "completed",
}

export interface ITotals {
  total: number;
  items: number;
  taxes: number;
  shipping: number;
  promotion: number;
  itemCount: number;
  quantityCount: number;
  itemsSubtotal: number;
  orderPromotion: number;
  orderPromotionPercentage: number;
  orderCouponPromotion: number;
  orderCouponPromotionPercentage: number;
}

export enum PaymentMethodCode {
  PAYPAL = "paypal",
  BANK_TRANSFER = "bank-transfer",
  STRIPE = "stripe",
  BRAINTREE = "braintree",
}

export interface IBankTransferDetails {
  accountName: string;
  accountNumber: string;
  swift: string;
  bankName: string;
  bankCode: string;
  bankAddress: string;
}

export interface IPaymentMethod {
  code: PaymentMethodCode;
  name: string;
  description: string;
  instructions: string;
  bankDetails: IBankTransferDetails;
  image?: IImage;
}

export interface IExpressPaymentMethod {
  code: PaymentMethodCode;
}

export enum PaymentState {
  CART = "cart",
  NEW = "new",
  PROCESSING = "processing",
  COMPLETED = "completed",
  FAILED = "failed",
  CANCELLED = "cancelled",
  REFUNDED = "refunded",
}

export interface IPayment {
  state: PaymentState;
  method: IPaymentMethod;
}

export interface IProductRedeemOptions {
  redeemable: boolean;
}

export interface IRedeemCode {
  product: string;
  activationCode: string;
  used: boolean;
  redeemUrl: string;
}

export interface IFeature {
  longTitle?: string;
  shortTitle?: string;
  subTitle: string;
  description: string;
  linkTitle: string;
  contentPageSlug?: string;
  image: IImage;
}

export interface IPlanFeature {
  name: string;
  isAvailable: boolean;
}

export interface IPlan {
  code: string;
  title: string;
  alternativeTitle?: string;
  description?: string;
  color: string;
  features: IPlanFeature[];
  productSlug: string;
  price: IPrice;
}

interface ISupportedFeature {
  name: string;
  availableFor?: string[];
}

type TSupportedPlan = Omit<IPlan, "features">;

export interface ISupportedPlans {
  features?: ISupportedFeature[];
  plans?: TSupportedPlan[];
}

type TFeatureGroups = Record<
  string,
  {
    name: string;
    features: IFeature[];
    image?: string;
  }
>;

export interface IFeaturePlan {
  featureGroups?: TFeatureGroups;
  name: string;
}

export interface IBlogCategory {
  slug?: string;
  name?: string;
  code: string;
}

export interface ISimilarBlog {
  image?: Partial<IImage>;
  blogCategory: IBlogCategory;
  title: string;
  slug: string;
  publishedAt: string;
  popular: boolean;
}

export interface IBlog {
  blogCategory: IBlogCategory;
  publishedAt: string;
  popular: boolean;
  summary: string;
  images?: Partial<IImage[]>;
  similarBlogPosts?: ISimilarBlog[];
  metaTitle?: string;
}

export interface IVideo {
  src: string;
  width?: string; // number in string. no units provided.
  height?: string; // number in string. no units provided.
  widthType?: "px" | "%";
  heightType?: "px" | "%";
  autoPlay?: boolean;
  expand?: boolean;
}

export interface IDPDPCities {
  cities: string[];
}

export interface IDPDPickUpPoints {
  lockers: IDPDPickUpPoint[];
}

export interface IDPDPickUpPoint {
  street: string;
  id: string;
  name: string;
  postalCode: string;
}

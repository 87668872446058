import { FC, ReactNode } from "react";
import { IntercomProvider } from "react-use-intercom";

import { Box, useToken } from "@chakra-ui/react";
import dynamic from "next/dynamic";
import NextNProgress from "nextjs-progressbar";

import { IAppData } from "@middlewares/globalData";
import { useAppDispatch, useMount } from "@utils/hooks/hooks";
import { useSessionWatch } from "@utils/hooks/useSessionWatch";
import { getCart } from "features/cart/cartSlice";

import Footer from "./Footer";
import Navigation from "./Navigation/Navigation";

const CartMergeToast = dynamic(() => import("@components/CartMergeToast"), {
  ssr: false,
});

type TLayoutProps = IAppData & {
  children: ReactNode;
};

const Layout: FC<TLayoutProps> = ({
  children,
  footerNavLinks,
  headerNavLinks,
  socialLinks,
  appProviders,
  paymentProviders,
  languages,
  userNavLinks,
  affiliatesBlock,
  config,
  omnisendBlock,
  pageType,
}) => {
  const dispatch = useAppDispatch();

  useSessionWatch();

  useMount(() => {
    dispatch(getCart());
  });

  const [primary500] = useToken("colors", ["primary.500"]);

  return (
    <>
      <NextNProgress
        options={{
          showSpinner: false,
        }}
        color={primary500}
        startPosition={0.3}
        height={2}
      />

      <CartMergeToast />
      <Navigation
        headerNavLinks={headerNavLinks}
        languages={languages}
        userNavLinks={userNavLinks}
        pageType={pageType}
      />
      <IntercomProvider appId={config.intercom_secret_code} autoBoot={process.env.NODE_ENV === "production"}>
        <Box as="main" pt={{ base: "56px", md: 20 }} flex={1}>
          {children}
        </Box>
      </IntercomProvider>
      <Footer
        footerNavLinks={footerNavLinks}
        socialLinks={socialLinks}
        appProviders={appProviders}
        paymentProviders={paymentProviders}
        affiliatesBlock={affiliatesBlock}
        omnisendBlock={omnisendBlock}
        config={config}
        pageType={pageType}
      />
    </>
  );
};

export default Layout;

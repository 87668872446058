/* eslint-disable @typescript-eslint/no-use-before-define */
import { createElement, Fragment, ReactElement } from "react";

import {
  Text,
  Divider,
  Accordion,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  Button,
  Box,
} from "@chakra-ui/react";
import parse from "html-react-parser";
import dynamic from "next/dynamic";
import Link from "next/link";
import { FaRegPlayCircle } from "react-icons/fa";
import convertStyleToObject from "style-to-object";

import LinkButton from "@components/common/LinkButton";
import { Heading } from "@components/Heading/Heading";
import Image from "@components/Image";
import { VERTICAL_ALIGN_VALUE } from "@components/ResourceView/components/constants";
import { ISimilarBlog } from "@features/types";
import { LAYOUT_COLUMNS_CONFIG } from "@utils/layoutColumnsUtils";
import { camelizeObjectKeys, generateId } from "@utils/utils";

const ButtonGroup = dynamic(() => import("@components/ButtonGroup"));
const CarBrandBlock = dynamic(() => import("@components/CarBrandBlock"));
const AllFeaturesTab = dynamic(() => import("@components/AllFeaturesTab"));
const SideImagePlaceholder = dynamic(() => import("@components/SideImagePlaceholder"));
const BadgeList = dynamic(() => import("@components/BadgeList"));
const Post = dynamic(() => import("@components/blog/Post"));
const EmbeddedContent = dynamic(() => import("@components/EmbeddedContent"));
const ProductBlock = dynamic(() => import("@components/ProductBlock/ProductBlock"));
const AccordionItem = dynamic(() => import("@components/disclosures/AccordionItem"));

const VideoCarousel = dynamic(() => import("@components/VideoCarousel"), {
  ssr: false,
});

const VideoPlayer = dynamic(() => import("@components/VideoPlayer"), {
  ssr: false,
});

enum LinkType {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  SECONDARY_DARK = "secondary-dark",
  LINK = "link",
  LINK_DARK = "link-dark",
  REGULAR = "regular",
}

const BUTTON_PROPS_BY_LINK_TYPE = {
  [LinkType.PRIMARY]: null,
  [LinkType.SECONDARY]: {
    colorScheme: "gray",
  },
  [LinkType.SECONDARY_DARK]: {
    variant: "secondaryDark",
  },
  [LinkType.LINK]: {
    variant: "link",
  },
  [LinkType.LINK_DARK]: {
    variant: "linkDark",
  },
  [LinkType.REGULAR]: {
    variant: "link",
    paddingX: 0,
    color: "secondary.500",
    fontWeight: "normal",
  },
};

const parseStyle = (style) => camelizeObjectKeys(convertStyleToObject(style));

const parseLink = (node) => {
  const { style, "data-type": dataType, "data-icon": dataIcon, href = "", id, target, rel } = node.attribs;
  let linkType: LinkType;

  switch (dataType) {
    case LinkType.PRIMARY:
    case LinkType.SECONDARY:
    case LinkType.SECONDARY_DARK:
    case LinkType.LINK_DARK:
    case LinkType.REGULAR:
      linkType = dataType;
      break;
    default:
      linkType = LinkType.LINK;
      break;
  }

  return createElement(
    [LinkType.LINK, LinkType.LINK_DARK].includes(dataType) ? LinkButton : Button,
    {
      as: href ? Link : "span",
      href,
      target,
      rel,
      id,
      "data-test-id": node.attribs["data-test-id"],
      ...(dataIcon === "true"
        ? {
            leftIcon: createElement(FaRegPlayCircle, { color: "#2A69CE", size: "24px" }),
          }
        : {}),
      width: {
        base: [LinkType.PRIMARY, LinkType.SECONDARY, LinkType.SECONDARY_DARK].includes(dataType) ? "100%" : "auto",
        md: "auto",
      },
      style: { ...parseStyle(style) },
      ...BUTTON_PROPS_BY_LINK_TYPE[linkType],
    },
    parseNodeArray(node.children)
  );
};

const parseImage = (node) => {
  const style = parseStyle(node.attribs.style) as any;
  const width = parseInt(style?.width, 10);
  const height = parseInt(style?.height, 10);

  if (width && height) {
    return createElement(
      Image,
      {
        src: node.attribs?.src,
        width,
        height,
        objectFit: "contain",
        alt: node.attribs?.alt,
        priority: true,
      },
      null
    );
  }

  return createElement(
    node.name,
    { ...node.attribs, style: { ...parseStyle(node.attribs.style), display: "inline" } },
    null
  );
};

const parseVideo = (node) =>
  createElement(
    node.name,
    { ...node.attribs, style: { ...parseStyle(node.attribs.style) }, controls: true },
    "Your browser does not support the video tag."
  );

const parseTableElements = (node) => {
  const TABLE_ELEMENT_TO_COMPONENT = {
    thead: Thead,
    tbody: Tbody,
    tfoot: Tfoot,
    tr: Tr,
    th: Th,
    td: Td,
  };

  const parseRegularElement = (node) => {
    const element = TABLE_ELEMENT_TO_COMPONENT[node.name];
    const { style, ...attributes } = node.attribs;
    return element ? createElement(element, attributes, parseNodeArray(node.children)) : null;
  };

  switch (node.name) {
    case "table":
      return createElement(
        TableContainer,
        { w: "100%", whiteSpace: "break-spaces" },
        createElement(Table, { variant: "striped", colorScheme: "gray" }, parseNodeArray(node.children))
      );
    default:
      return parseRegularElement(node);
  }
};

const nodeHaveClass = (className) => (node) => !!(node?.attribs?.class || "").split(" ").find((i) => i === className);

const findChildrenByClassName = (node, className) => {
  const item = node?.children?.find(nodeHaveClass(className));

  if (item) {
    return item;
  }

  for (let i = 0; i < node?.children?.length; i++) {
    const n = node?.children[i];
    const childItem = findChildrenByClassName(n, className);
    if (childItem) {
      return childItem;
    }
  }

  return null;
};

const filterChildrenByClassName = (node, className) =>
  [
    ...(node?.children?.filter(nodeHaveClass(className)) || []),
    ...(node?.children || []).map((n) => (filterChildrenByClassName(n, className) || []).flat()),
  ].flat();

const parseAccordionList = (node) => {
  const itemsWrapper = findChildrenByClassName(node, "accordion-list-items");
  const itemNodes = filterChildrenByClassName(itemsWrapper, "collapsible-item");
  const accordionItems = itemNodes?.map((itemNode) => {
    const titleElement = findChildrenByClassName(itemNode, "collapsible-item-title-link");
    const bodyElement = findChildrenByClassName(itemNode, "collapsible-item-body");

    return {
      title: titleElement ? parseNodeArray(titleElement?.children) : null,
      body: bodyElement ? parseNodeArray(bodyElement?.children) : null,
    };
  });

  if (accordionItems?.length) {
    return createElement(
      Accordion,
      {
        allowToggle: true,
      },
      accordionItems.map((item, key) =>
        // eslint-disable-next-line react/no-array-index-key
        createElement(AccordionItem, { key, title: item.title, inheritBgColor: true }, item.body)
      )
    );
  }
  return null;
};

const parseButtonGroup = (node) =>
  createElement(
    ButtonGroup,
    {
      buttonCount: node.children.length || 1,
    },
    parseNodeArray(node.children)
  );

const parseLayoutColumns = (node) => {
  const forceSingleColumn = node.attribs.force;
  const columnCount = node.attribs?.columns || 1;
  const baseColumnCount = node.attribs?.basecolumns || undefined;
  const columnsConfig = node.attribs?.columns ? `repeat(${columnCount}, 1fr)` : undefined;
  const layoutConfig = node.attribs?.layout ? LAYOUT_COLUMNS_CONFIG.layout[node.attribs.layout] : undefined;
  const yMargin = node.attribs?.vmargin ? LAYOUT_COLUMNS_CONFIG.vmargin[node.attribs.vmargin] : { mb: 0, mt: "1rem" };
  const gridColumnGap = node.attribs?.gap ? LAYOUT_COLUMNS_CONFIG.gap[node.attribs.gap] : "1rem";
  const cWidth = node.attribs?.cwidth ? LAYOUT_COLUMNS_CONFIG.cwidth[node.attribs.cwidth] : {};
  const filteredChildren = node?.children?.filter(
    (item) => parseInt(item.attribs?.slot?.split("column")[1], 10) <= columnCount
  );

  const baseColumnConfig = baseColumnCount ? `repeat(${baseColumnCount}, 1fr)` : "1fr";
  const gridTemplateColumnsConfig = forceSingleColumn
    ? layoutConfig ?? columnsConfig
    : { base: baseColumnConfig, md: layoutConfig ?? columnsConfig };

  return createElement(
    Box,
    {
      w: "full",
      display: "grid",
      gridTemplateColumns: gridTemplateColumnsConfig,
      gap: gridColumnGap,
      ...cWidth,
      ...yMargin,
    },
    filteredChildren.map((item, key) => {
      const itemChildren = item.children.length > 0 ? parseNodeArray(item.children) : null;

      const verticalAlign = item.attribs["data-vertical-align"];
      const blockVerticalAlignment = verticalAlign ? { alignSelf: VERTICAL_ALIGN_VALUE[verticalAlign] } : {};

      const isMobileCentered = item.attribs["mobile-align-center"] === "1";
      const mobileBlock = createElement(
        Box,
        {
          // eslint-disable-next-line react/no-array-index-key
          key,
          display: { base: "block", md: "none" },
          textAlign: "left",
          ...(isMobileCentered
            ? {
                sx: {
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  "> div": {
                    textAlign: "center !important",
                  },
                },
              }
            : {}),
          ...blockVerticalAlignment,
        },
        itemChildren
      );

      const desktopBlock = createElement(
        Box,
        {
          // eslint-disable-next-line react/no-array-index-key
          key,
          display: { base: "none", md: "block" },
          ...blockVerticalAlignment,
        },
        itemChildren
      );

      return createElement(
        Box,
        {
          // eslint-disable-next-line react/no-array-index-key
          key,
          ...blockVerticalAlignment,
        },
        [mobileBlock, desktopBlock]
      );
    })
  );
};

export const getDataProps = (node) => {
  try {
    return JSON.parse(node.attribs?.["data-props"]);
  } catch (error) {
    return {};
  }
};

const renderElement = (node) => {
  // Only custom elements has dash in name
  if (node.name.indexOf("-") > -1) {
    return renderCustomComponent(node.name, getDataProps(node));
  }

  if (node.name === "svg" && (node.attribs?.class || "").includes("referral-svg")) {
    return createElement(
      node.name,
      {
        style: { ...parseStyle(node.attribs.style) },
        width: node.attribs.width,
        height: node.attribs.height,
        viewBox: node.attribs.viewbox,
        fill: node.attribs.fill,
        xmlns: node.attribs.xmlns,
      },
      node.children.length > 0 ? parseNodeArray(node.children) : null
    );
  }

  if (node.name === "path" && (node.attribs?.class || "").includes("referral-svg")) {
    return createElement(
      node.name,
      {
        style: { ...parseStyle(node.attribs.style) },
        d: node.attribs.d,
        fill: node.attribs.fill,
      },
      node.children.length > 0 ? parseNodeArray(node.children) : null
    );
  }

  return createElement(
    node.name,
    { style: { ...parseStyle(node.attribs.style) } },
    node.children.length > 0 ? parseNodeArray(node.children) : null
  );
};

const renderCustomComponent = (type, props): ReactElement | null => {
  switch (type) {
    case "yt-video":
      return (
        <VideoPlayer
          {...props}
          width={props.width ? `${props.width}${props.widthType || "px"}` : undefined}
          height={props.height ? `${props.height}${props.heightType || "px"}` : undefined}
        />
      );
    case "product-block": {
      if (!props) {
        return null;
      }

      return <ProductBlock description={parseHtml(props.product?.description || "")} {...props} />;
    }
    case "functional-block": {
      if (props == null) {
        return null;
      }

      return <AllFeaturesTab allFeatures={Object.values(props)} />;
    }
    case "badge-block":
      return <BadgeList {...props} />;
    case "blog-post-link": {
      if (!props) {
        return null;
      }

      const { title, publishedAt, blogCategory, image, slug } = props.blogPost as ISimilarBlog;

      return (
        <Post
          category={{ ...blogCategory, name: `#${blogCategory.name}` }}
          imageSrc={image?.path}
          title={title}
          publishDate={publishedAt}
          slug={slug}
          shouldApplyBorder
          applyDateFormatter
          applyMobileStyles
          width={props.width}
          alignment={props.alignment}
        />
      );
    }
    case "car-brand-block": {
      return <CarBrandBlock {...props} />;
    }
    case "video-carousel":
      return (
        <VideoCarousel
          {...{
            ...props,
            videoCarousel: props?.videoCarousel.map((item) => ({
              ...item,
              description: parseHtml(item.description),
            })),
          }}
        />
      );
    case "embedded-content": {
      return <EmbeddedContent key={generateId(10)} content={props.content} alignment={props.alignment} />;
    }
    case "side-image-placeholder": {
      return <SideImagePlaceholder />;
    }
    default:
      return null;
  }
};

const parseContainer = (node) => {
  // Exception for accordion, because it's using sylius plugin.
  if ((node.attribs?.class || "").includes("accordion-list")) {
    return parseAccordionList(node);
  }
  if ((node.attribs?.class || "").includes("button-group")) {
    return parseButtonGroup(node);
  }

  if ((node.attribs?.class || "").includes("countdown-timer")) {
    return createElement(
      node.name,
      {
        style: {
          ...parseStyle(node.attribs.style),
        },
        className: node.attribs.class,
      },
      parseNodeArray(node.children)
    );
  }

  return createElement(
    node.name,
    {
      style: {
        ...parseStyle(node.attribs.style),
      },
    },
    parseNodeArray(node.children)
  );
};

const parseParagraph = (node) => {
  let fontSize = "textSize.bodyText";

  const hasClass = (className) => nodeHaveClass(className)(node);

  if (hasClass("custom_title")) {
    fontSize = "textSize.title";
  } else if (hasClass("custom_headline")) {
    fontSize = "textSize.regular";
  } else if (hasClass("custom_label")) {
    fontSize = "textSize.labels";
  } else if (hasClass("custom_caption")) {
    fontSize = "textSize.small";
  } else if (hasClass("custom_footnote")) {
    fontSize = "textSize.micro";
  }

  return createElement(
    Text,
    { as: "div", fontSize, style: { ...parseStyle(node.attribs.style) } },
    parseNodeArray(node.children)
  );
};

export const parseTag = (node) => {
  switch (node.name) {
    case "h1":
    case "h2":
    case "h3":
    case "h4":
    case "h5":
    case "h6":
      return createElement(
        Heading,
        {
          as: node.name,
          css: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            "&:first-letter": {
              textTransform: "uppercase",
            },
          },
          fontWeight: ["h1", "h2"].includes(node.name) ? "normal" : "bold",
          style: { ...parseStyle(node.attribs.style) },
        },
        parseNodeArray(node.children)
      );
    case "p":
      return parseParagraph(node);
    case "hr":
      return createElement(Divider, null, null);
    case "a":
      return parseLink(node);
    case "img":
      return parseImage(node);
    case "video":
      return parseVideo(node);
    case "table":
    case "thead":
    case "tbody":
    case "tfoot":
    case "tr":
    case "th":
    case "td":
      return parseTableElements(node);
    case "blockquote": {
      return createElement(
        node.name,
        {
          ...node.attribs,
          style: { ...parseStyle(node.attribs.style) },
        },
        parseNodeArray(node.children)
      );
    }
    case "div":
    case "span":
      return parseContainer(node);
    case "iframe":
      return createElement(node.name, { ...node.attribs, style: { ...parseStyle(node.attribs.style) } }, null);
    case "layout-columns":
      return parseLayoutColumns(node);
    default:
      return renderElement(node);
  }
};

const parseText = (node) => {
  return node.data;
};

const parseNode = (node) => {
  switch (node.type) {
    case "tag":
      return parseTag(node);
    case "text":
      return parseText(node);
    default:
      return null;
  }
};

const parseNodeArray = (nodeArray) => {
  // eslint-disable-next-line react/no-array-index-key
  return nodeArray.map((node, key) => createElement(Fragment, { key }, parseNode(node)));
};

export const parseHtml = (html) => {
  if (typeof html !== typeof "") {
    return null;
  }

  const result = parse(html, {
    replace: (domNode) => {
      return parseNode(domNode);
    },
  });

  return result;
};

export const API_ROUTES = {
  carts: {
    getNewCart: "/carts",
    getCartByToken: (cartToken: string): string => `/carts/${cartToken}`,
  },
  siteSettings: "/site-settings",
  countries: "/countries",
  getUserOrders: "/orders",
  getOrder: (tokenValue: string): string => `/orders/${tokenValue}`,
  getOrderItemRedeemCodes: (orderToken: string, itemIdentifier: number): string =>
    `/orders/${orderToken}/items/${itemIdentifier}/redeem-codes`,
  getAllProducts: "/products/all",
  newUserAddress: "/address-book/",
  me: "/me",
  editUserAddress: (addressId: number): string => `/address-book/${addressId}`,
  carBrand: "/car-brand",
  pages: {
    bySlug: (slug: string, params?: string): string => `/pages/by-slug/${slug}${params || ""}`,
    byPageType: (pageType: string): string => `/pages/info/?resourceCategory=${pageType}`,
  },
  supportedVehicles: {
    getOca: (searchParams: string): string => `/supported-vehicles/oca${searchParams}`,
    getOcas: "/supported-vehicles/ocas",
    getVehicleByVin: (vin: string): string => `/supported-vehicles/${vin}/vin`,
    getVehicleBases: (make: string): string => `/supported-vehicles/${make}/bases`,
  },
  omnisend: {
    viewOrder: (cartToken: string, email: string): string => `/omnisend/view-order/${cartToken}/${email}`,
    cartRecovery: (currentCartToken: string, omnisendToken: string): string =>
      `/omnisend/recover/${currentCartToken}/${omnisendToken}`,
  },
  productsComparison: (carBrand: string): string => `/compare-products?carBrand=${carBrand}`,
  productDevices: (carBrand: string): string => `/product-devices?carBrand=${carBrand}`,
  productPlans: (carBrand: string, supported?: boolean): string =>
    `/product-plans?carBrand=${carBrand}${supported ? "&supported=true" : ""}`,
  getProductRedeemOptions: (productCode: string, cartToken: string): string =>
    `/products/by-code/${productCode}/redeem-availability/${cartToken}`,
  getBlock: (blockCode: string): string => `/blocks/${blockCode}`,
  getPopularFeatures: (carBrand: string): string => `/features/${carBrand}?popular=true`,
  staticPages: "/static-pages",
  blog: {
    categories: "/blog-categories",
    getPosts: (query?: string): string => `/blog-posts${query}`,
  },
  userAddress: {
    getAddresses: "/address-book/",
    getAddress: (addressId: string): string => `/address-book/${addressId}`,
    setDefaultAddress: (addressId: string): string => `/address-book/${addressId}/default`,
  },
  sectionMenu: {
    legalSideMenu: "/section-menu/legal",
  },
  contactUs: "/contact-us/submit",
  userManual: "/current-user-manual",
  checkout: {
    customerData: (cartToken: string): string => `/checkout/${cartToken}/customer`,
  },
  wami: (ip: string): string => `/where-am-i/${ip}`,
  referralValidation: "/referrals/validate",
  vinChecker: "/vin-checker",
  dpdCities: "/dpd/cities",
  dpdPickupPoints: "/dpd/lockers",
};

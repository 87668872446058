import { extendTheme } from "@chakra-ui/react";

import Accordion from "./components/Accordion";
import Alert from "./components/Alert";
import Button from "./components/Button";
import Form from "./components/Form";
import Input from "./components/Input";
import Menu from "./components/Menu";
import Radio from "./components/Radio";
import Select from "./components/Select";
import Tabs from "./components/Tabs";
import Tooltip from "./components/Tooltip";
import borderRadius from "./foundations/borders";
import colors from "./foundations/colors";
import { styles } from "./foundations/styles";
import { fonts } from "./typography/fonts";
import { fontSizes } from "./typography/fontSizes";
import { textStyles } from "./typography/textStyles";

const theme = extendTheme({
  styles,
  colors,
  fonts,
  fontSizes,
  ...borderRadius,
  textStyles,
  components: { Accordion, Button, Form, Input, Radio, Select, Menu, Tooltip, Alert, Tabs },
  sizes: {
    container: {
      xl: "1252px",
    },
  },
});

export default theme;

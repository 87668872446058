import pino, { LoggerOptions } from "pino";

const config: LoggerOptions = {
  name: "Server logs",
  level: process.env.NEXT_LOG_LEVEL || "debug",
};

if (process.env.NODE_ENV !== "production") {
  config.transport = {
    target: "pino-pretty",
    options: {
      colorize: true,
    },
  };
}

export const logger = pino(config);

export const timeTook = (message: string, startTime: number): void => {
  logger.debug(`${message}, ${new Date().getTime() - startTime}ms`);
};
